.input {
  @apply py-4 text-xl;

  border-bottom: 1px solid #ddd;
  letter-spacing: 1px;
  background: transparent;

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.8);
  }

  &.serif {
    @apply italic font-serif;
  }
}
