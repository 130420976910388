.detail-v3 {
  @apply mt-2 lg:mt-0;
  &__name {
    color: var(--colors-black);

    font-family: Domaine Disp;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.8125rem; /* 150% */
    /* margin-bottom: 0.25rem; */
  }

  &__venue {
    color: var(--colors-black);
    font-family: Maison Neue;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 200;
    line-height: 2.1875rem;
    letter-spacing: 0.0625rem;

    margin-bottom: 3.69rem;
  }

  &__card {
    display: flex;
    border-radius: 0.625rem;
    border: 1px solid #ddd;
    background-color: #fff;
    color: var(--colors-black);
    padding: 1.06rem;
    padding-right: 2.62rem;

    font-family: Maison Neue;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.875rem; /* 200% */
    letter-spacing: 0.0625rem;

    margin-bottom: 1.62rem;

    transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      color 0.15s ease-in-out;

    &__header {
      color: #0015d6;

      font-family: Maison Neue;
      font-size: 0.725rem;
      font-style: normal;
      font-weight: 100;
      letter-spacing: 0.1875rem;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 0.6rem;
    }

    &__content {
      color: #000;

      font-family: Maison Neue;
      font-size: 1rem;
      font-style: normal;
      font-weight: 100;
      line-height: 1.5rem;
      letter-spacing: 0.0625rem;

      & > div {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__icon {
      margin-top: 0.3rem;
      /* margin-right: 2.06rem; */
      width: 2.8rem;
      flex-basis: 3.66rem;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__location {
      cursor: pointer;
    }

    &__location:hover {
      background-color: var(--colors-blue);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

      & * {
        color: #fff;
        stroke: #fff;
      }

      & * img {
        stroke: #fff !important;
      }
    }
  }

  &__gallery {
    overflow: hidden;
    border-radius: 0.625rem;
    margin-bottom: 4rem;
  }

  &__description {
    @apply my-12 lg:my-0 text-[0.9rem] lg:text-[1.1rem] leading-7 lg:leading-[1.9rem];

    color: #000;

    font-family: Maison Neue;

    font-style: normal;
    font-weight: 200;
    letter-spacing: 0.0625rem;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;

    & p {
      margin-bottom: 1.5rem;
    }
  }
}
