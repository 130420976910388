.autoselect-item {
    transition: background-color ease .2s;
    color: #333 !important;
    overflow-x: hidden;
    padding: 0.375rem 1rem;
}

.autoselect-item>label {
    width: 100%;
}

.MuiTypography-body1 {
    font-family: "Maison Neue", sans-serif !important;
    font-weight: lighter !important;
    letter-spacing: 1.5px !important;
}

.autoselect {
    //padding-top: 14px;
}

.autoselect-selected {
    padding-top: 1.71rem;
    padding-bottom: 1.72rem;
    overflow: hidden;
    padding-right: 20%;
}

.autoselect-selected>span {
    max-width: 100%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200;
    font-size: 1rem;
}

.autoselect-item:hover {
    background-color: #b7b7b7;

}

.autoselect__checkbox {
    color: #1b1f23 !important;
}

.autoselect__checkbox:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

@media (max-width: theme('screens.md')) {
    .autoselect-item {
        color: var(--colors-white) !important;
    }

    .autoselect-item:hover {
        background-color: var(--colors-black);
    }

    .autoselect__checkbox {
        color: var(--colors-white) !important;
    }

}