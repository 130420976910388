.form__intro {
  @apply mb-12 text-2xl;

  @media md {
    @apply mb-20;
  }
}

.form__section {
  @apply mb-12;

  input[type="text"],
  input[type="email"],
  input[type="url"],
  textarea {
    font-size: 1rem !important;
    font-weight: 300;
  }

  .select__indicator,
  .select__indicator.close {
    /* TODO: (SeedyROM) Doesn't work for close */
    filter: brightness(1);
  }
}

.form__section__field {
  @apply mb-8 w-full;
}

.form__section__header {
  @apply mb-8 text-2xl;
}

.form__section__label {
  @apply mb-4 text-xl;
}
