.nav-bar-filters {
  @apply xs:hidden block;

  margin-left: -4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.62rem;

  @media (max-width: theme("screens.lg")) {
    & .select_v3 {
      font-size: 0.75rem !important;

      & .input {
        font-size: 0.88rem;
      }
    }
    & .select_v3__container {
      padding: 0.5rem 1.435rem;
    }

    & .select_v3__dropdown {
      position: fixed;
      left: 1rem;
      right: 1rem;
    }

    & .select_v3__dropdown-container {
      width: calc(100vw - 2rem);
      overflow-y: auto;
    }
  }
}
