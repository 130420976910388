.kick-start {
  @apply flex flex-col mt-2;
  gap: 1.62rem;
  cursor: default;
  user-select: none;
  color: var(--colors-black);

  @screen lg {
    @apply grid grid-cols-2;
    gap-y: 0;
    gap-x: 1.62rem;
  }

  &__section {
    margin-bottom: 2.12rem;

    &:last-child {
      @apply hidden lg:block;
      margin-bottom: 0;
    }
  }

  &__image {
    border-radius: 0.625rem;
    margin-bottom: 2.19rem;
    aspect-ratio: 7/4;
    overflow: hidden;
    border: 1px solid transparent;
    transition: border-color 0.3s ease-in-out;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__section:hover &__image {
    border: 1px solid var(--colors-blue);
  }

  &__category {
    text-transform: uppercase;
    color: var(--colors-blue);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.8125rem; /* 450% */
    letter-spacing: 0.1875rem;
  }

  &__title {
    font-family: Domaine Disp;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.8125rem; /* 150% */
    cursor: pointer;

    & img {
      display: inline-block;
      margin-left: 0.25rem;
      width: 1.625rem;
      height: 1.375rem;
      flex-shrink: 0;
    }
  }

  &__section:hover &__title {
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    text-decoration-thickness: 0.1rem;
  }
}
