.detail {
  @screen md {
    font-size: 12px;
  }

  min-height: auto;

  @screen md {
    min-height: 500px;
  }

  @screen xs {
    @apply text-base;
  }

  &__full_info {
    @apply block mt-8;

    @screen sm {
      @apply mt-20;

      display: grid;
      grid-gap: 3rem;
      grid-template-columns: 1fr 2.1fr;
    }

    /* @screen lg {
      grid-gap: 2.25rem;
    } */
  }

  &__container-padding {
    /*@apply px-4;*/

    @media only screen and (min-width: theme("screens.md")) {
      @apply py-4 px-8;
    }
  }

  &__info {
    @apply block;

    @screen sm {
      /* display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 2.5fr 1fr;
      gap: 2.5em 2.5em;
      grid-template-areas: "image-gallery image-gallery image-gallery info" "image-gallery image-gallery image-gallery info" "description description description info"; */
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: 3.175fr 1fr;
    }

    &.event {
      @apply hidden;

      @screen sm {
        /* display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 2.5fr 1fr;
        gap: 2.5em 2.5em;
        grid-template-areas: "image-gallery image-gallery image-gallery info" "image-gallery image-gallery image-gallery info" "description description description info"; */
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: 3.175fr 1fr;
      }
    }

    &__mobile {
      @apply block my-8 text-left;

      @screen sm {
        @apply hidden;
      }

      @screen xs {
        @apply font-thin;
      }
    }

    &__gallery {
      @apply mb-4;

      @screen md {
        @apply mb-0;
      }
    }

    &__info {
      @apply hidden;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @screen sm {
        padding-top: 0;
        padding-bottom: 6rem;
      }

      @screen md {
        @apply block;
      }

      @screen lg {
        padding-bottom: 11rem;
      }
    }

    &__info.editorial {
      @apply hidden;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @screen sm {
        padding-top: 0;
        padding-bottom: 1rem;
      }

      @screen md {
        @apply block;
      }

      @screen lg {
        padding-bottom: 1rem;
      }
    }

    &__description {
      /* @apply pb-12 pt-6; */
      @apply mb-8 text-left;

      @screen sm {
        @apply pb-16 pt-0;
      }

      @screen lg {
        @apply pb-32;
      }

      @screen xs {
        @apply font-thin;
      }
    }

    &__header {
      @apply text-2xl mt-1;

      @screen md {
        @apply mt-0;
      }
    }

    &__subheader {
      @apply text-lg;
      margin-bottom: 1rem;

      @screen sm {
        margin-bottom: 3.5rem;
      }

      & a {
        /* @apply underline; */
      }
    }

    &__header-small {
      @apply uppercase mt-8 mb-2;
      font-size: 0.6rem;
      letter-spacing: 2px;
      font-weight: 200;

      @screen sm {
        @apply mt-12 mb-2;
      }

      @screen xs {
        @apply text-base;
      }

      &.no-margin {
        margin-top: 0;
      }
    }
  }

  &__meta,
  &__related {
    @apply block;

    grid-gap: 3rem;

    @screen md {
      @apply grid grid-cols-2;
    }

    @screen lg {
      @apply grid grid-cols-4;
    }

    & .event {
      @apply mb-0;

      @screen md {
        @apply mb-16;
      }
    }
  }

  &__meta {
    @apply pb-3;

    @screen md {
      @apply grid pb-16 gap-y-12;
      grid-template-columns: repeat(3, 1fr);
    }

    @screen lg {
      @apply pb-40;
    }

    & > div {
      @apply pb-4;
    }
  }

  &__editorial-content {
    @apply mt-8 mb-8;

    @screen md {
      @apply mt-16 mb-16;
    }

    @screen lg {
      @apply mt-32 mb-64;
    }

    & > div {
      @apply text-sm;
      display: block !important;
      grid-gap: 4rem 1.5rem !important;
      margin-top: 3rem;
      margin-bottom: 3rem;

      div {
        margin-bottom: 2rem;
      }

      @screen md {
        @apply text-base;
      }

      @screen lg {
        margin-top: 0rem;
        margin-bottom: 0rem;
        display: grid !important;
        grid-gap: 8rem 3rem !important;
      }
    }
  }
}
