.category-filter {
    .dropdown {
        &__menu {
            @apply py-4 flex justify-between w-full;
        }

        &__items {
            position: relative;
        }

        & .indicator {
            padding-top: 4px;
        }
    }

    &__view {
        @apply pb-8;
        border-color: #ddd;
        border-width: 1px;
    }

    &__root_category {
        @apply py-4 px-4 w-full cursor-pointer;
        font-weight: normal;

        &:hover, &.active {
            background-color: var(--colors-black);
            color: var(--colors-white);
            font-weight: normal;
        }
    }

    &__subcategory {
        @apply p-4 cursor-pointer;

        transition: all 0.3s ease-in-out;

        &:hover, &.active {
            background-color: var(--colors-black);
            color: var(--colors-white);
        }
    }
}
