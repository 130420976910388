/*
 *= require_tree .
 *= require_self
 */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "../assets/fonts/maison-neue.css";
@import "../assets/fonts/domaine-disp.css";

@import "./global.css";
@import "./events/detail.css";
@import "./auth/login.css";
@import "./home.css";
@import "./markdown.css";

/* TODO: How to fix this SSR workaround? Not sure... */
@import "../components/MobileNavbar.css";

:root {
  --colors-black: #111111;
  --colors-white: #faf9f7;
  --colors-gray: #999999;
  --colors-tan: #a5a29b;
  --colors-blue: #0015d6;
  --colors-blue-light: rgba(0, 21, 214, 0.8);

  --z-index-ui: 5;
}
