/* TODO: Add more font family settings later */
@font-face {
  font-family: "Domaine Disp";
  src: url("~assets/fonts/DomaineDisp-Regular.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Domaine Disp";
  src: url("~assets/fonts/DomaineDisp-RegularItalic.otf");
  font-weight: 300;
  font-style: italic;
}
