/* @import "../../../stylesheets/application.css"; */

.mobile-modal {
    @apply block fixed left-0 right-0 bottom-0 top-0 w-full h-full px-6 py-4;


    background: #000;
    color: var(--colors-white);

    z-index: 99;


    &__header {
        @apply flex flex-row justify-between items-center mb-4;

        &__title {
            @apply block text-lg;
        }

        &__close {
            @apply text-sm underline;
            z-index: 4;
            cursor: pointer;
        }
    }


    &__items {
        @apply flex flex-col h-full mt-10;

        z-index: 999;

        .button {
            @apply text-lg px-6 py-6 w-full;
            border-color: var(--colors-white);

        }


        a {
            @apply block text-xl mb-4;
        }
    }

    &__select {
        @media (max-width: theme('screens.md')) {
            .input {

                &::placeholder {
                    color: var(--colors-white);
                }
            }

        }
    }
}