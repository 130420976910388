@import "../../stylesheets/application.css";

.event_v3 {
  @apply pb-8 block relative;

  @screen md {
    @apply pb-12;
  }

  @screen lg {
    @apply pb-16;
  }

  &__map-icon {
    @apply absolute opacity-100 md:opacity-0 top-[1px] right-[1px] lg:top-0 lg:right-0;
    width: 32px;
    z-index: 1;
    padding: 0.5rem;
    border-radius: 0 0.625rem 0 0.625rem;
    border: 1px solid var(--colors-blue);
    transition: all 0.3s ease-in-out;
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;

    & img {
      width: 100%;
      height: auto;
    }

    &:hover {
      background: var(--colors-blue);
      border-left: 1px solid var(--colors-white);
      border-bottom: 1px solid var(--colors-white);

      & img {
        filter: invert(1) hue-rotate(180deg);
      }
    }
  }
  &:hover &__map-icon {
    opacity: 1;
  }

  &__photo-container {
    border-radius: 0.625rem;
    border: 1px solid transparent;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover &__photo-container {
    border: 1px solid var(--colors-blue);
  }

  &__photo {
    position: relative;
    width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
    aspect-ratio: 4/3;
    /* background-image: url("~assets/images/misc/nophoto.png"); */
    background-color: #f9f8ea;

    & img {
      object-fit: cover;
      object-position: center;
      height: 100%;
    }
  }

  &__subheader {
    font-size: 0.6rem;
    font-weight: 200;
    letter-spacing: 0.2rem;
  }

  &__info {
    @apply flex justify-between text-xs;
    text-align: left;

    & > *:nth-child(2) {
      text-align: right;
    }
  }

  &__title {
    @apply font-light;
  }

  & .marker *:hover {
    padding: none !important;
    background: transparent !important;
  }

  &__on_view_now {
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.1875rem;
    position: absolute;
    display: inline-block;
    padding: 0.42rem 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-transform: uppercase;
    text-align: center;
    height: inherit !important;
    background: rgba(255, 255, 255, 0.75);
    color: #333;
  }
}
