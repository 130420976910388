.event-list {
  @apply relative;

  &__events
  /* &__filters*/ {
    @apply grid grid-cols-1 md:mb-20 md:grid-cols-2 lg:grid-cols-4;

    /* display: grid;
    grid-template-columns: 1fr; */
    grid-gap: 0 1.62rem;
    margin-top: 2rem;
  }

  &__filters {
    @apply pb-4 pt-2;
    /* position: sticky; */
    top: 0;
    z-index: 2;
    /* background-color: var(--colors-white); */

    @media (max-width: theme("screens.md")) {
      position: relative;
      z-index: 1;
      display: block;
      font-size: 1rem;
      font-weight: 100;
      /* margin-bottom: -3rem; */

      & > * {
        @apply mb-8;

        &:last-child {
          @apply mb-0;
        }
      }

      .input,
      .event-list__toggle_map,
      .dropdown.select,
      .select-selected {
        @apply px-4;
        border: 1px solid #ddd;
      }

      .select__indicator {
        right: 1rem;
      }

      .button {
        @apply px-6 py-6 w-full;
        font-size: 0.95rem;
      }
    }

    /* &::before {
      @apply mb-8 text-lg block;
      content: "Filters: ";
      letter-spacing: 1px;

      @screen md {
        @apply hidden;
      }
    } */
  }

  &__events,
  &__map {
    min-height: 500px;
    margin-bottom: 5rem;
  }

  &__modal {
    background: var(--colors-white);
    color: var(--colors-black);
    position: sticky;
    top: 25px;
    left: 0;
    right: 0;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 50;
  }

  &__map {
    @apply mb-0;
  }

  &__no-events {
    @apply flex justify-center items-center;
    grid-column: 1/-1;

    font-size: 2rem;
    opacity: 0.9;
    font-style: italic;
  }

  &__toggle_map {
    @apply py-4 font-thin cursor-pointer;

    border-bottom: 1px solid #ddd;

    @media (max-width: theme("screens.md")) {
      font-size: 1rem;
      font-weight: 200;

      svg {
        width: 28px;
        filter: brightness(0) invert(1);
      }
    }
  }
}

.collecting-page-filter {
  @apply mt-8;
}
