.discover,
.email {
  @apply block;

  & > div {
    @apply mb-12;
  }

  @screen lg {
    @apply grid grid-cols-2 gap-8;

    & > div {
      @apply mb-0;
    }
  }

  &__img img {
    width: 100%;
    min-width: 200px;
  }

  &__copy {
    @apply flex flex-col justify-between;

    &__header {
      @apply text-4xl pb-10;

      line-height: 1;
    }

    &__copy {
      @apply leading-loose;
    }

    &__links > * {
      @apply flex flex-row justify-between py-4;

      border-top: 1px solid #222;

      &:last-child {
        border-bottom: 1px solid #222;
      }

      &.coming-soon {
        @apply italic opacity-75 cursor-wait;
      }
    }
  }

  @media (max-width: theme('screens.sm')) {
    input[type="text"], input[type="email"] {
      @apply py-2;
    }
  }
}

.email {
  &__copy {
    &__header {
      @apply text-3xl;
      @screen lg {
        @apply text-5xl;    
      }
    }
  }
}
