.events-map {
  padding: 3rem 0;
  border-radius: 0.625rem;

  &__left {
    height: 80vh;
    min-height: 600px;
    overflow-y: scroll;
    overscroll-behavior: contain;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.62rem;

    & .event {
      transition: all 0.3s ease-in-out;
      & * {
        text-decoration: inherit !important;
      }

      &.hover,
      &:hover {
        outline: none;
        border: none;
        /* background: rgba(0, 0, 0, 0.07); */
        /* padding: 1rem; */

        &.marker {
          & * {
            font-weight: normal;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__right {
    overscroll-behavior: contain;
    overflow: hidden;
    border-radius: 0.625rem;

    & .event {
      @apply p-4;

      width: 350px;
    }
  }

  @screen sm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.62rem;
  }

  & .gm-style-iw .gm-style-iw-c {
    @apply p-4;
  }
}
