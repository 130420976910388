@import "../../ui/Input.css";

.login {
  &__container {
    @apply items-center justify-center p-3 flex flex-col;

    @screen md {
      @apply p-8;
      min-height: 370px;
    }
  }

  &__form {
    @apply p-4 px-8 mb-12;

    width: 100%;
    max-width: 450px;
    border: 1px solid var(--colors-black);

    @screen sm {
      @apply p-8 mb-0;
      margin-bottom: 10rem;
    }
  }

  &__title {
    @apply text-2xl pb-6;

    @screen md {
      @apply pb-12;
    }
  }

  & input[type="email"],
  & input[type="password"] {
    @apply py-1 w-full;
  }
}
