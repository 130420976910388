.colors-filter-select {
    margin-top: 14px;
}

.colors-filter-option {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.colors-filter-option__color {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #ddd;
    margin-right: 10px;
}
