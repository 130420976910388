@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-Thin.woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-ThinItalic.woff");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-Light.woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-LightItalic.woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-Book.woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-BookItalic.woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-Medium.woff");
  font-weight: 400;
  font-style: medium;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-MediumItalic.woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-Demi.woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-DemiItalic.woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-Bold.woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Maison Neue";
  src: url("~assets/fonts/MaisonNeueWEB-BoldItalic.woff");
  font-weight: 800;
  font-style: italic;
}
