.select {
  @apply relative;

  height: 100%;
  max-height: 50px;

  @media (max-width: theme("screens.md")) {
    @apply my-8;
  }

  & * {
    user-select: none;
    cursor: pointer;
  }

  & input {
    @apply text-base;
    width: 100%;
    font-weight: 200;
  }

  &__indicator {
    position: absolute;
    top: 1rem;
    right: 0;
    color: #828281;
    display: block;
    -webkit-filter: invert(1);
    filter: invert(1);

    @media (min-width: theme("screens.md")) {
      top: 1.5rem;
      filter: brightness(0);
      -webkit-filter: brightness(0);
    }

    & img {
      width: 28px;
      line-height: 0;

      transition: transform 0.15s ease-in-out;

      /* &.indicator {} */

      @media (min-width: theme("screens.md")) {
        width: 1rem;
        /* filter: invert(1);
        -webkit-filter: invert(1); */

        &.close {
          margin-right: -12px;
          margin-top: -4px;
          width: 1.9rem;
        }
      }

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown {
    @apply absolute w-full;

    max-height: 400px;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;

    z-index: var(--z-index-ui);

    background: var(--colors-white);

    @media (max-width: theme("screens.md")) {
      background: #000;
    }

    & > * {
      @apply py-4 text-base;

      /* border-bottom: 1px solid var(--colors-black); */
      padding-left: 1rem;

      &:hover {
        background-color: black;
        color: #fff;
        transition: all 0.3s ease-in-out;

        @media (max-width: theme("screens.md")) {
          background: var(--colors-black);
        }
      }
    }

    /* TODO: MOVE ME */

    & > .Calendar {
      @apply p-0 text-sm w-full shadow-none;
      min-height: inherit;

      /* border-bottom: 1px solid var(--colors-black); */
      background-color: var(--colors-tan);
      color: var(--colors-white);

      &:hover {
        background-color: var(--colors-tan);
        color: var(--colors-white);
        transition: all 0.3s ease-in-out;

        @media (max-width: theme("screens.md")) {
          background: var(--colors-tan);
        }
      }
    }

    & .Calendar__sectionWrapper {
      min-height: 27em;
    }

    & .Calendar__weekDays {
      padding: 0 2.6em;
      display: grid;
      grid-template-columns: repeat(7, 1fr);

      & > * {
        /* display: block; */
        text-align: center;
        width: 100%;
      }
    }

    & .Calendar__weekRow {
      display: grid;
      grid-template-columns: repeat(7, 1fr);

      & > * {
        /* display: block; */
        text-align: center;
        width: 100%;
      }
    }

    &
      .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(
        .-selectedBetween
      ):not(.-selected) {
      border-radius: 10px;
      &:hover {
        border-radius: 10px;
        color: black !important;
      }
    }
    & .Calendar__day.-ltr.-selectedStart {
      color: black !important;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      &:hover {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
    }

    & .Calendar__day.-ltr.-selectedBetween {
      color: black !important;
    }

    & .Calendar__day.-ltr.-selectedEnd {
      color: black !important;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      &:hover {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    & .Calendar__day,
    & .Calendar__day.-today {
      color: #fff !important;
      &:hover {
        color: black !important;
      }
    }

    & .Calendar__monthText,
    & .Calendar__yearText {
      color: #fff;
    }

    & .Calendar__monthArrow {
      filter: invert(1);
    }

    & .Calendar__monthYear.-shown > *:hover,
    .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus,
    .Calendar__monthYear > *.-activeBackground {
      color: black !important;
    }

    /* TODO: END MOVE ME */

    opacity: 0;
    margin-top: 5px;
    visibility: hidden;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    transition: all 0.3s ease-in-out;

    &.open {
      opacity: 1;
      margin-top: 0px;
      visibility: visible;
    }
  }

  &__dropdown-calendar {
    max-height: 445px !important;
  }
}

.select-selected {
  @apply text-base;

  width: 100%;
  max-height: 36px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
}

.select__indicator--custom {
  top: 7px;
}
