@import "../stylesheets/application.css";

.hero {
  &__info {
    @apply font-thin text-lg;
    line-height: 1.5;
    margin-right: 5rem;

    @screen xs {
      @apply mt-12 mr-4 mb-12;
    }

    @screen lg {
      font-size: 1.5rem;
      line-height: 2;
    }

    @screen xl {
      margin-right: 9rem;
    }

    @media (min-width: 1440px) {
      margin-right: 15rem;
    }

    a {
      @apply underline inline;
    }

    h2 {
      @apply font-thin text-2xl;
      margin-right: 7rem;
      line-height: 1.5;

      @screen xs {
        margin-right: 1px;
      }

      @screen lg {
        font-size: 2.5rem;
      }
    }
  }

  &__select {
    @apply underline cursor-pointer;
  }

  .select__dropdown {
    border-top: 1px solid #ddd;
  }
}
