/*
* Aspect ratio hacks
*/
/* [style*="--aspect-ratio"] > :first-child {
  width: 100%;
} */
[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  /* [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  } */
}

/*
* Fuck the standard box sizing
*/
* {
  box-sizing: border-box;
}

/*
* Fix iOS rounded inputs
*/
textarea,
input[type] {
  -webkit-appearance: none !important;
  border-radius: 0 !important;
}

html {
  font-size: 14px;
  min-width: 375px;
}

/*
* Limit the client viewport size
*/
body {
  min-width: 375px;
  width: 100%;
  scroll-behavior: smooth;
}

/*
* Global and body settings
*/
body {
  background-color: var(--colors-white);
  font-family: "Maison Neue", sans-serif;
  letter-spacing: 1.15px;
  font-weight: 200;

  @screen xs {
    font-size: 9px;
  }
}

/*
* Contain an image tag into a background-image style cover
*/
.image-contains {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  div {
    max-width: 100%;
    width: 100vw;
  }
}

/*
* Turbolinks style
*/
.turbolinks-progress-bar {
  height: 3px;
  background-color: var(--colors-black);
}

/*
* Create a container that handles medium and below paddings
*/
.container-md-padding {
  @media only screen and (max-width: theme("screens.sm")) {
    @apply py-3 px-4;
    max-width: 100% !important;
  }

  @media only screen and (min-width: theme("screens.sm")) {
    @apply py-4 px-8;
  }
}

/*
* Style the navbar
*/
.navbar {
  position: sticky;
  top: 0;
  left: 0;
  background: var(--colors-white);
  z-index: 5;

  border-bottom: 1px solid transparent;

  transition: all ease-in-out 0.3s;

  &__logo {
    @apply select-none;
    display: flex;
    align-items: center;

    @media screen and (min-width: theme("screens.md")) {
      max-width: 166px;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & img {
      width: 100%;
      margin-left: -3px;
    }
  }

  &__border-visible {
    border-bottom: 1px solid #ddd;
  }

  &__icon {
    @apply select-none;

    line-height: 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    @apply flex flex-row items-center mx-3;

    &:last-child {
      @apply mr-0;
    }

    & img {
      width: 100%;
    }

    @screen xs {
      width: 0.9rem;
      height: 0.9rem;
      @apply mx-2;
    }
  }

  &__event {
    h2 {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: theme("screens.md")) {
    &__logo {
      max-width: 180px;
    }
  }

  @media only screen and (max-width: theme("screens.sm")) {
    &__logo {
      max-width: 150px;
    }
  }
}

.button {
  @apply px-5 py-3;
  text-transform: capitalize;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 200;
  line-height: 1.5;
  box-sizing: border-box;
  border-radius: 0;

  border: 1px solid #ddd;

  @screen lg {
    border: 1px solid #999;
    transition: all 0.35s ease;

    &.hover,
    &:hover {
      background-color: #333;
      color: #fff;
      transition: all 0.35s ease;
    }
  }

  &.dark {
    background: var(--colors-black);
    color: var(--colors-white);
  }

  &.light {
    background: var(--colors-white);
    color: var(--colors-black);
  }

  @media only screen and (max-width: 660px) {
    @apply px-4 py-2;
    font-size: 0.95rem;
  }

  @media only screen and (max-width: 320px) {
    @apply px-4 py-2;
    font-size: 0.88rem;
  }

  &--disabled {
    color: var(--colors-gray);
    border: 1px solid var(--colors-gray);
    cursor: not-allowed;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.container {
  max-width: 1600px;
}

.alert,
.notice {
  div {
    @apply py-5 mx-auto container-md-padding;
  }

  background-color: rgba(25, 25, 225, 0.3);
}

/*
* Footer component
*/
.footer {
  display: grid;
  //grid-template-columns: 1fr 1fr;
  font-size: 9px;
  margin-bottom: 2rem;

  /*
  @screen lg {
    display: grid;
    //grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    font-size: 0.88rem;
    margin-bottom: 4rem;
  }
  */

  @screen lg {
    @apply grid grid-cols-4 mb-32;
    grid-gap: 3rem;
    font-size: 12px;
    margin-top: 5rem;
  }

  &__logo {
    grid-column: 1/-1;

    @screen md {
      grid-column: inherit;
      margin-bottom: 1.5rem;
    }

    & img {
      width: 100%;
      max-width: 170px;
      margin-top: -0.55rem;
      margin-left: -0.2rem;
    }
  }

  &__heading {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    font-size: 11px;
    color: var(--colors-blue);

    @screen md {
      margin-bottom: 3rem;
      margin-top: 0;
      font-size: 0.88rem;
    }

    @screen sm {
      margin-bottom: 1.5rem;
    }
  }

  &__link {
    @apply mb-2 block;

    & span {
      border-bottom-width: 1px;
      border-bottom-color: transparent;

      &:hover {
        @apply cursor-pointer;

        border-bottom-color: var(--colors-black);
      }
    }
  }

  &__email-form {
    grid-column: 1/-1;

    @screen sm {
      grid-column: inherit;
    }

    & input[type="email"] {
      width: 100%;
    }

    & input[type="submit"] {
      @apply text-xs mb-6;
      padding: 0.75rem 1rem;
      border-radius: 0.625rem !important;
      border: 1px solid var(--colors-blue);
      /* background: #faf9f7; */
      color: var(--colors-blue);
    }
  }

  &__copyright {
    @apply mt-5 lg:mt-0;
  }
}

a.detail-link-back {
  @apply pb-4 cursor-pointer font-thin block;
  margin-top: 0rem;

  @screen lg {
    @apply pb-8;
    margin-top: 0rem;
  }

  @screen xl {
    @apply pb-8;
    margin-top: 0rem;
  }
}

.modal-open {
  overflow: hidden;
}
