@import "../stylesheets/application.css";

.platform-dropdown {
  width: 275px;
}

@media (max-width: theme("screens.sm")) {
  .platform-dropdown {
    width: 200px;
  }
}
