.category-picker {
  display: flex;
  align-items: center;
  position: relative;

  &__modal {
    padding: 2rem;
    padding-bottom: 0;
    width: 100%;
    max-width: calc(1600px - 4rem);
    border-radius: 0.625rem;
    border: 1px solid #ddd;
    background-color: #fff;
    color: var(--colors-black);
    max-height: 85vh;
    overflow-y: auto;
  }

  &__more {
    @apply w-full lg:w-[220px];

    @media (max-width: theme("screens.lg")) {
      border-color: var(--colors-blue) !important;
      color: var(--colors-blue) !important;
      padding: 0.75rem 2.875rem !important;
    }

    margin-right: 0 !important;
  }

  &__categories {
    flex-grow: 1 !important;
    overflow: hidden;
    display: block;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 2.9375rem;
    scrollbar-width: none;
  }

  &__category,
  &__more {
    @apply block md:inline-flex text-center md:text-left md:mr-[1.625rem];

    opacity: 1;
    align-items: center;
    background: #faf9f7;
    border-radius: 0.625rem;
    flex-grow: 1;
    border: 1px solid #ddd;
    color: #000;
    font-family: Maison Neue;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 200;
    justify-content: center;
    letter-spacing: 0.0625rem;
    line-height: normal;
    padding: 1rem 2.875rem;
    transition: all 0.2s ease-in-out;
    user-select: none;
    margin-bottom: 1.5rem;

    &--selected,
    &--selected:hover {
      background: var(--colors-blue);
      color: #fff;
      border-color: #fff;
    }

    &--invisible {
      display: none;
      opacity: 0;
    }

    &:hover {
      cursor: pointer;
      background: var(--colors-blue-light);
      color: #fff;
      border-color: #fff;
    }
  }
}
