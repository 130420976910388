.event-carousel {
  @apply px-4;
  overflow-x: hidden;

  /* &__scroll {
        &-horizontal {
            overflow-y: hidden;
            overflow-x: auto;
            width: auto;
            min-width: 100%;
            min-height: 100%;
        }

        &-vertical {
            position: absolute;
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
        }
    } */

  &__header {
    background: var(--colors-white);
    z-index: 1;
    /* position: -webkit-sticky;
        position: sticky;
        top: 50px; */
    padding-bottom: 0.5rem;

    h1 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__navigation {
    display: block;
    position: fixed;
    background: var(--colors-white);
    border-top: 1px solid #ddd;
    padding: 3rem 0 0.7rem;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  &__details {
    display: grid !important;
    min-height: 45vh;
    @apply grid-cols-2 gap-x-2;
  }

  .carousel .control-dots {
    position: fixed;
    left: 0;
    bottom: 30px;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
  }

  .slider-container {
    width: 100%;
    height: 100%;

    /* Add this */
    position: fixed;
    top: 0;
    left: 0;
  }

  .detail__info__header-small {
    @screen xs {
      font-size: 0.6rem;
    }
  }
}
