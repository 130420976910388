.mobile-navbar {
  &__icon {
    filter: invert(1);
    width: 0.88rem;
    height: 0.88rem;
    line-height: 0;
    margin-top: -3px;
  }

  &__menu {
    @apply block fixed top-0 left-0 right-0 bottom-0;

    background: var(--colors-black);
    color: var(--colors-white);

    &__close {
      position: absolute;
      filter: invert(1);
      margin-top: -4px;
      z-index: 9999;

      top: 1.25rem;
      right: 0.5rem;
      width: 2rem;
      height: 2rem;
    }

    &__links {
      @apply flex flex-col justify-between items-baseline h-full;

      a {
        @apply block text-xl mb-4;
      }

      .navbar {
        background: transparent !important;
      }

      .navbar__logo img {
        filter: invert(1) !important;
      }

      .footer {
        @apply px-4 w-full;

        & .footer__heading {
          color: #fff !important;
        }

        & .footer__link {
          @apply text-xs;
        }

        input[type="email"],
        input[type="submit"] {
          background-color: transparent;
          border-color: #f6f6f6;
          color: #f6f6f6;
        }

        input[type="submit"] {
          margin-bottom: 0;
        }

        input[type="email"]::placeholder {
          color: #b6b6b6;
        }
      }
    }
  }
}
