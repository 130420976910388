@import "../stylesheets/application.css";

.featured {
  &__left {
    @apply col-span-1;
  }

  &__header {
    @apply uppercase mt-4 mb-4;

    font-size: 0.6rem;
    font-weight: 200;
    letter-spacing: 2px;

    & + div {
      @apply text-sm;
    }

    @screen md {
      @apply mt-16 mb-1;
    }
  }

  margin-top: 2rem;

  &__info {
    @apply flex-grow text-base;

    h2 {
      @apply mb-2 text-3xl leading-10;

      letter-spacing: 2px;

      @media (min-width: theme("screens.sm")) {
        @apply mt-2;
      }

      @media (max-width: theme("screens.sm")) {
        @apply mt-0 text-2xl;
      }

      @media (min-width: theme("screens.sm")) and (max-width: theme("screens.md")) {
        @apply mt-10;
      }

      @media (min-width: theme("screens.md")) and (max-width: theme("screens.lg")) {
        @apply mt-10 mb-1;
      }

      @media (min-width: theme("screens.lg")) {
        @apply mt-0 mb-1;
      }
    }

    h3 {
      @apply mt-1 text-xl;
      margin-bottom: 3rem;

      @media (max-width: theme("screens.md")) {
        @apply mt-2 mb-4 text-base;
      }

      @media (min-width: theme("screens.sm")) and (max-width: theme("screens.md")) {
        @apply mb-4;
      }
    }
  }

  &__when {
    @apply mb-10 leading-loose;

    @media (max-width: theme("screens.md")) {
      @apply mb-0 mt-4 text-sm leading-loose;
    }
  }

  &__description {
    @apply mb-10 mt-32 text-sm leading-loose;
    text-spacing: 0.5px;

    @media (max-width: theme("screens.md")) {
      @apply mb-16 leading-loose mt-0;
    }

    @media (max-width: theme("screens.xs.max")) {
      @apply text-xs;

      margin-bottom: 1rem;
    }
  }

  .button {
    /* @apply col-span-1; */
    width: 100%;
    @apply p-6;
  }

  &__navigation {
    @apply flex-none;

    @screen xs {
      @apply gap-4;
    }

    @screen sm {
      @apply gap-5;
    }

    @screen md {
      @apply gap-5;
    }

    @screen lg {
      @apply gap-5;
    }

    @screen xl {
      @apply gap-12;
    }
  }

  &__right {
    @apply col-span-2;
  }

  &__photo {
    @apply image-contains;
    max-height: 675px;
  }

  @screen xs {
    @apply flex flex-col-reverse;
    /* gap-3; */
  }

  @screen sm {
    @apply flex flex-col-reverse;
    /* gap-3; */
  }

  @screen md {
    @apply flex flex-col-reverse;
    /* gap-5; */
  }

  @screen lg {
    @apply grid grid-cols-4 gap-5;
  }

  @screen xl {
    @apply grid grid-cols-4;
    grid-gap: 1.62rem;
  }
}

.button {
  position: relative;
  overflow: hidden;

  &__timer {
    display: block;
    background-color: #666;
    height: 3px;
    transition: 0.5s linear;
    transition-delay: 0.5s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: theme("screens.sm")) {
  .featured::after {
    @apply mb-6 text-lg block;

    content: "Featured:";
    letter-spacing: 1px;
    /* margin-bottom: 0.5rem; */
  }

  .featured__right {
    @apply mb-6;
  }

  /* @screen md { */
  /* @apply hidden; */
  /* } */
}
