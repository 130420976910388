.toggle {
  @apply relative text-base;

  height: 100%;
  max-height: 50px;

  @media (max-width: theme("screens.md")) {
    @apply my-8;
  }

  & button {
    @apply py-4 w-full;
    border-bottom: 1px solid var(--colors-white);
  }

  & * {
    user-select: none;
    cursor: pointer;
  }

  &__indicator {
    display: inline-block;

    &.close {
      margin-right: -8px;
    }

    & img {
      width: 1rem;
      line-height: 0;

      transition: transform 0.15s ease-in-out;

      &.close {
        margin-top: -8px;
        width: 1.9rem;
      }

      &.open {
        transform: rotate(180deg);
      }
    }

    &__icon {
      @media (max-width: theme("screens.md")) {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }
    }
  }
}
