.card-carousel {
  @apply block;

  @screen lg {
    @apply grid grid-cols-4 gap-12;
  }

  &__info {
    @apply flex flex-col justify-between;
    background: var(--colors-white);
    color: #000;
    transition: all 0.35s ease;


    @screen md {
      border-width: 1px;
      padding: 3rem;

      &.hover,
      &:hover {
        background-color: #e8e7e5;
        color: #000;
        transition: all 0.35s ease;
        border: 1px solid #ddd;
      }
    }

    &__heading {
      @apply font-sans font-light text-lg;

      line-height: 1.5;

      @screen sm {
        @apply text-4xl mb-8 font-thin;
      }

    }

    &__description {
      @apply mb-8;
    }

    &__view-all {
      & a {
        @apply underline;
      }
    }
  }

  &__buttons {
    @apply hidden;

    @screen md {
      @apply block;
    }

    &>button {
      @apply inline-block cursor-pointer mr-8;
    }
  }

  .card {


    @screen md {
      @apply pb-0;
    }

    .event__photo {
      @apply mt-8;

      @screen md {
        @apply mt-0;
      }
    }
  }

  &__mobile-navigation {
    @apply grid grid-cols-2 gap-4;

    .button {
      @apply text-lg px-6 py-6;

      border-color: #A8A8A8;
      line-height: 0.8;
    }

    @screen md {
      @apply hidden;
    }
  }

  .carousel.carousel-slider li.slide.selected {
    z-index: 0 !important;
  }

}