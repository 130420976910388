.image-gallery {
  & .carousel-status {
    display: none;
    visibility: hidden;
  }

  & button {
    display: none;
  }
}
