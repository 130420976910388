@import "../stylesheets/application.css";

.event {
  @apply pb-8 block;

  @screen md {
    @apply pb-16;
  }

  @screen lg {
    @apply pb-20;
  }

  &__photo {
    width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
    /* background-image: url("~assets/images/misc/nophoto.png"); */
    background-color: #f9f8ea;
  }

  &__subheader {
    font-size: 0.6rem;
    font-weight: 200;
    letter-spacing: 0.2rem;
  }

  &__info {
    @apply flex justify-between text-xs;
    text-align: left;

    & > *:nth-child(2) {
      text-align: right;
    }
  }

  &__title {
    @apply font-light;
  }

  .marker *:hover {
    padding: none !important;
    background: transparent !important;
  }

  &__on_view_now {
    @apply text-xs p-2;

    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    height: inherit !important;
    background: rgba(255, 255, 255, 0.75);
    color: #333;
  }
}
