/* @import "../../../stylesheets/application.css"; */

.dimensions-filter {
    .dropdown {
        &__menu {
            @apply py-4 flex justify-between w-full;

            @media (max-width: theme('screens.md')) {
                font-size: 1rem;
                border-bottom: 1px solid #ddd;
            }
        }

        & .indicator {
            padding-top: 4px;
        }
    }

    &__view {
        @apply py-4 px-8 border;
        border-color: #ddd;
    }

    &__toggler {}

    &__sliders {
        @screen lg {
            @apply grid grid-cols-3 gap-16;
        }
    }

    &__slider {}
}

.MuiSlider-track,
.MuiSlider-track {
    height: 1px !important;
}

.MuiSlider-markLabel {
    font-family: "Maison Neue", sans-serif !important;
    font-weight: 100 !important;
    letter-spacing: 1px !important;
}

@media (max-width: theme('screens.md')) {
    .MuiSlider-thumb {
        color: var(--colors-white);
    }

    .MuiSlider-rail {
        color: var(--colors-white);
    }
}


/*
.dimensions-dropdown {
    top: 122.5px;
    z-index: 50;
    padding: 40px 20px;
    width: 100%;
    height: 300px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.dimensions-dropdown-slider {
    width: 28%;
    height: 100%;
}

.dimensions-dropdown-slider-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.dimensions-dropdown-toggler {
    padding-left: 20px;
    margin-bottom: 20px;
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dimensions-dropdown-toggler > button {
    margin-right: 1px;
    outline: none;
}

.dimensions-dropdown-toggler > button:disabled {
    background: #e5e5e5;
    cursor: initial !important;
}

@media screen and (max-width: 767px) {
    .dimensions-dropdown {
        height: 500px;
        top: 183px;
    }

    .dimensions-dropdown-slider-wrap {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;
    }

    .dimensions-dropdown-slider {
        width: 90%;
        height: 100%;
    }

    .dimensions-dropdown-toggler {
        padding: 0;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .dimensions-dropdown-toggler > button {
        width: 170px;
        height: 40px;
    }
}
*/