.dropdown {
  @apply cursor-pointer select-none;

  outline: none;

  /* border-bottom: 1px solid var(--colors-white); */
  border-bottom: 1px solid #ddd;

  .dropdown__items.open {
    display: block;
  }

  .indicator {
    line-height: 0;

    img {
      transition: all 0.3s ease-in-out;
      filter: invert(1);
      width: 1rem;

      @media (max-width: theme('screens.md')) {
        width: 28px;
      }
    }

    &.open-indicator img {
      transform: rotate(180deg);
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: theme('screens.md')) {
      border-bottom: 1px solid #ddd;
    }

  }

  &__items {
    display: none;
    position: absolute;

    left: 0;
    top: 2.5rem;

    border: 1px solid var(--colors-black);
    color: var(--colors-black);
    background: var(--colors-white);
  }

  &__item {
    @apply px-4 py-4;

    min-width: 275px;
    transition: all ease-in-out 0.3s;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: var(--colors-white);
      background: var(--colors-black);
    }
  }
}

.dropdown--no-items {
  position: relative;
  border: none;
}

.dropdown-scrollable {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}