.paragraph-input {
  @apply font-thin text-2xl;

  letter-spacing: 1px;
  background: transparent;
  display: inline;
  cursor: pointer;
  min-width: 1px;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.8);
  }

  @screen lg {
    font-size: 2.5rem;
  }
}

.hide-span {
  position: absolute;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: -100;
}
