.slider-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.slider-filter__title {
    margin-top: 10px;
    margin-bottom: 10px;
}

.slider-filter__subtitle {
    margin-bottom: 10px;

    font-size: 12px;
}

.slider-filter__color {
    color: #1b1f23 !important;
}
